import { createContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as api from 'context/GlobalContext/api';
import {
  CompanyReportPeriod,
  CompanySearchOption,
  GlobalContextProviderProps,
  GlobalContextValue,
  LeagueTableOptions,
  Statistics,
} from 'context/GlobalContext/GlobalContext.types';
import * as URL from 'router/url';
import { SelectOptionWithDescription } from 'screens/IndustryAveragesPage/utils';

export const GlobalContext = createContext<GlobalContextValue>({
  companySearchOptions: {} as CompanySearchOption[],
  isLoadingCompanySearchOptions: false,
  unitOptions: {} as SelectOptionWithDescription[],
  isLoadingUnits: false,
  yearsLookup: {},
  yearsOptions: [] as CompanyReportPeriod[],
  isLoadingYearOptions: false,
  getPeriods: () => new Promise(() => {}),
  leagueTableOptions: {} as LeagueTableOptions,
  isLoadingLeagueTableOptions: false,
  industryAverages: [],
  isIndustryAveragesLoading: false,
  getLatestReports: () => new Promise(() => {}),
  isLoadingLatestReports: false,
  latestReports: [],
  getStatistics: () => new Promise(() => {}),
  statistics: {} as Statistics,
});

const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const location = useLocation();
  const {
    companySearchOptions,
    isLoadingCompanySearchOptions,
    getCompanySearchOptions,
  } = api.useGetCompanySearchOptions();

  const { getAllowedUnits, isLoadingUnits, unitOptions } =
    api.useGetAllowedUnits();

  const { yearsLookup, yearsOptions, isLoadingYearOptions, getPeriods } =
    api.useGetPeriods();

  const { industryAverages, isIndustryAveragesLoading, getIndustryAverages } =
    api.useGetIndustryAverages();

  const { getLatestReports, isLoadingLatestReports, latestReports } =
    api.useGetLatestReports();

  const { getStatistics, statistics } = api.useGetStatistics();

  const {
    leagueTableOptions,
    isLoadingLeagueTableOptions,
    getLeagueTableOptions,
  } = api.useGetLeagueTableOptions();

  useEffect(() => {
    if (
      location.pathname !== URL.NOT_AVAILABLE &&
      companySearchOptions === undefined
    )
      getCompanySearchOptions();
  }, [companySearchOptions, getCompanySearchOptions, location.pathname]);

  useEffect(() => {
    if (
      industryAverages === undefined &&
      location.pathname === URL.INDUSTRY_AVERAGES
    ) {
      getIndustryAverages();
    }
  }, [getIndustryAverages, location.pathname, industryAverages]);

  useEffect(() => {
    if (
      leagueTableOptions === undefined &&
      location.pathname !== URL.NOT_AVAILABLE
    ) {
      getLeagueTableOptions();
    }
  }, [getLeagueTableOptions, leagueTableOptions, location.pathname]);

  useEffect(() => {
    if (unitOptions === undefined && location.pathname !== URL.NOT_AVAILABLE) {
      getAllowedUnits();
    }
  }, [getAllowedUnits, location.pathname, unitOptions]);

  const value = useMemo(
    () => ({
      companySearchOptions,
      isLoadingCompanySearchOptions,
      isLoadingUnits,
      unitOptions,
      yearsLookup,
      yearsOptions,
      isLoadingYearOptions,
      getPeriods,
      leagueTableOptions,
      isLoadingLeagueTableOptions,
      industryAverages,
      isIndustryAveragesLoading,
      getLatestReports,
      isLoadingLatestReports,
      latestReports,
      getStatistics,
      statistics,
    }),
    [
      companySearchOptions,
      isLoadingCompanySearchOptions,
      isLoadingUnits,
      unitOptions,
      yearsLookup,
      yearsOptions,
      isLoadingYearOptions,
      getPeriods,
      leagueTableOptions,
      isLoadingLeagueTableOptions,
      industryAverages,
      isIndustryAveragesLoading,
      getLatestReports,
      isLoadingLatestReports,
      latestReports,
      getStatistics,
      statistics,
    ],
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
