import { ExternalDomainLinkProps } from 'components/ExternalDomainLink/ExternalDomainLink.types';
import { EventActions } from 'utils/GoogleAnalitycsEvents/EventActions';
import { GA4eventTrack } from 'utils/GoogleAnalitycsEvents/eventTrackForGA';

export const ExternalDomainLink = ({
  title,
  GA4_label,
  href,
  className,
  withChildren,
  children,
}: ExternalDomainLinkProps) => (
  <a
    className={className}
    target="_blank"
    href={href}
    rel="noreferrer"
    onClick={GA4eventTrack.bind(this, {
      action: EventActions.EXTERNAL_LINK_CLICK,
      label: GA4_label || title,
    })}
  >
    {withChildren ? children : title}
  </a>
);
