export const CompanyLandingPageSections = {
  OVERVIEW: 'overview',
  COMPARE: 'compare',
  HISTORIC_PERFORMANCE: 'historic-performance',
  ESTIMATED_ESG_DATA: 'estimated-esg-data',
  TAXONOMY: 'taxonomy',
  CARBON_METRICS: 'carbon-metrics',
  COMPANY_INFORMATION: 'company-information',
  FINANCIAL_INSTRUMENTS: 'financial-instruments',
};

export const CompanyLandingPageSubsections = {
  FACTORS: 'factors',
  PAI_INDICATORS: 'pai-indicators',
  QUESTIONS: 'questions',
  BOARD_DISTRIBUTION: 'board-distribution',
} as const;
