export const IndustryPallet = [
  {
    label: 'Agriculture, forestry and fishing',
    code: 'A',
    color: '#4caf50',
  },
  {
    label: 'Mining and quarrying',
    code: 'B',
    color: '#a84924',
  },
  {
    label: 'Manufacturing',
    code: 'C',
    color: '#003748',
  },
  {
    label: 'Electricity, gas, steam and air conditioning supply',
    code: 'D',
    color: '#f9c4a0',
  },
  {
    label: 'Water supply',
    code: 'E',
    color: '#ced8f4',
  },
  {
    label: 'Construction',
    code: 'F',
    color: '#b6b6b6',
  },
  {
    label: 'Wholesale and retail trade',
    code: 'G',
    color: '#91e5ce',
  },
  {
    label: 'Transporting and storage',
    code: 'H',
    color: '#3b5998',
  },
  {
    label: 'Accommodation and food service activities',
    code: 'I',
    color: '#7ef79c',
  },
  {
    label: 'Information and communication',
    code: 'J',
    color: '#ffdb58',
  },
  {
    label: 'Financial and insurance activities',
    code: 'K',
    color: '#3498db',
  },
  {
    label: 'Real estate activities',
    code: 'L',
    color: '#e74c3c',
  },
  {
    label: 'Professional, scientific and technical activities',
    code: 'M',
    color: '#e87f38',
  },
  {
    label: 'Administrative and support service activities',
    code: 'N',
    color: '#aa183f',
  },
  {
    label: 'Public administration and defense',
    code: 'O',
    color: '#f79cda',
  },
  {
    label: 'Education',
    code: 'P',
    color: '#7f96f7',
  },
  {
    label: 'Human health and social work activities',
    code: 'Q',
    color: '#8f398f',
  },
  {
    label: 'Arts, entertainment and recreation',
    code: 'R',
    color: '#a24b67',
  },
  {
    label: 'Other services activities',
    code: 'S',
    color: '#26a69a',
  },
  {
    label: 'Activities of households as employers',
    code: 'T',
    color: '#693faa',
  },
  {
    label: 'Activities of extraterritorial organisations and bodies',
    code: 'U',
    color: '#3f18aa',
  },
];
