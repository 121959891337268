import { Dispatch } from 'react';
import { NavLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import { ReactComponent as ArrowForwardIcon } from 'assets/ArrowForwardIcon.svg';
import { ReactComponent as LatestReportsIcon } from 'assets/LatestReportsIcon.svg';
import { ReactComponent as LeagueTabelsIcon } from 'assets/LeagueTabelsIcon.svg';
import { ReactComponent as LearningCenterIcon } from 'assets/LearningCenterIcon.svg';
import { ReactComponent as ProductsIcon } from 'assets/ProductsIcon.svg';
import ArticleIcon from '@mui/icons-material/Article';
import * as URL from 'router/url';
import { LearningCenterSections } from 'screens/LearningCenterPage/TabMenu/LearningCenterSections';
import classes from './NavigationDrawer.module.css';

type Props = {
  setIsDrawerOpen: Dispatch<React.SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  onNavClick: (navLink: string) => void;
};

const mobileMenuPositions = [
  {
    label: 'latest reports',
    link: URL.LATEST_REPORTS,
    icon: <LatestReportsIcon />,
  },
  {
    label: 'league tables',
    link: URL.LEAGUE_TABLE,
    icon: <LeagueTabelsIcon />,
  },
  { label: 'products', link: URL.PRODUCTS, icon: <ProductsIcon /> },
  {
    label: 'learning center',
    link: `${URL.LEARNING_CENTER}/${LearningCenterSections.FAQ}/`,
    icon: <LearningCenterIcon />,
  },
  {
    label: 'Insights',
    link: `${URL.INSIGHTS}`,
    icon: <ArticleIcon />,
  },
];

export const NavigationDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  onNavClick,
}: Props) => {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      className={classes.drawer}
    >
      <Box
        role="navigation"
        onClick={() => setIsDrawerOpen(false)}
        sx={{ minWidth: '220px', borderRadius: 0 }}
      >
        <div className={classes.drawerHeader}>
          <IconButton>
            <CloseIcon sx={{ color: 'white' }} fontSize="small" />
          </IconButton>
        </div>
        <List className={classes.menuList}>
          {mobileMenuPositions.map(({ link, label, icon }) => (
            <ListItem key={link} disablePadding className={classes.menuItem}>
              <ListItemButton>
                <NavLink
                  activeStyle={{
                    color: '#3822a3',
                    fontWeight: 600,
                  }}
                  className={classes.link}
                  data-testid={link}
                  to={link}
                  onClick={() => onNavClick(link)}
                >
                  <>
                    <div>
                      {icon}
                      <span>{label}</span>
                    </div>
                    <ArrowForwardIcon />
                  </>
                </NavLink>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
