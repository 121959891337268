import { Tooltip } from '@nordictrustee/nt-ui-library';
import { IndustryPallet } from 'consts/IndustryPallet';
import classes from './createIndustryIndicator.module.css';

export const createIndustryIndicator = (code: string) => {
  return IndustryPallet.filter((industry) => industry.code === code).map(
    (industry) => (
      <Tooltip title={industry.label}>
        <div
          key={industry.code}
          className={classes.indicator}
          style={{ backgroundColor: industry.color }}
        />
      </Tooltip>
    ),
  );
};
