import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useDetectBreakpoint } from 'hooks/useDetectBreakpoint';

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const isMobile = useDetectBreakpoint('sm');

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
      return;
    }
    !matchPath(pathname, '/company/') && window.scrollTo(0, 0);
  }, [isMobile, pathname]);

  return null;
};
