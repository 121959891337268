import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Search } from '@mui/icons-material';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import { Button } from '@nordictrustee/nt-ui-library';
import { GlobalContext } from 'context/GlobalContext/GlobalContext';
import { CompanySearchOption } from 'context/GlobalContext/GlobalContext.types';
import { useDetectBreakpoint } from 'hooks/useDetectBreakpoint';
import useQuery from 'hooks/useQuery';
import { useToggle } from 'hooks/useToggle';
import * as URL from 'router/url';
import { OverviewSections } from 'screens/CompanyLandingPage/screens/Overview/TabMenu/OverviewSections';
import { CompanyLandingPageSections } from 'screens/CompanyLandingPage/TabMenu/CompanyLandingPageSections';
import { SearchBarProps } from 'components/SearchBar/SearchBar.types';
import { EventType, Keyboard } from 'utils/constants';
import { createIndustryIndicator } from 'utils/createIndustryIndicators/createIndustryIndicator';
import classes from './SearchBar.module.css';

export const SearchBar = ({
  isHeaderSearchBar = false,
  isDrawerSearch = false,
  onSearch,
  setIsSearchDrawerOpen,
}: SearchBarProps) => {
  const query = useQuery();
  const isMobile = useDetectBreakpoint('sm');

  const [searchInputValue, setSearchInputValue] = useState(
    query.get('searchValue') || '',
  );

  const { companySearchOptions, isLoadingCompanySearchOptions } =
    useContext(GlobalContext);
  const { push } = useHistory();
  const [isDropdownOpen, handleDropdownOpen, handleDropdownClose] = useToggle();

  const options = useMemo(
    () =>
      companySearchOptions?.filter((company) => {
        return (
          company.organizationNumber.includes(searchInputValue) ||
          company.lei?.includes(searchInputValue) ||
          company.name.toLowerCase().includes(searchInputValue.toLowerCase())
        );
      }) || companySearchOptions,
    [companySearchOptions, searchInputValue],
  );

  const getSearchCompanies = useCallback(() => {
    onSearch
      ? onSearch(searchInputValue) // only in company search result page
      : push({
          pathname: URL.COMPANY_SEARCH_RESULT,
          search: new URLSearchParams({
            searchValue: searchInputValue,
          }).toString(),
        });
  }, [onSearch, push, searchInputValue]);

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === Keyboard.ENTER || event.code === Keyboard.NUMPAD_ENTER) {
      getSearchCompanies();
      handleDropdownClose();
    }
  };

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInputValue(event.target.value.trimStart());
    },
    [setSearchInputValue],
  );

  const handleOptionSelect = useCallback(
    (option: CompanySearchOption) => {
      if (option === null) return;
      push(
        `${URL.COMPANY}/${option.publicID}/${
          CompanyLandingPageSections.OVERVIEW
        }/${OverviewSections.FACTORS}/${encodeURIComponent(
          option.name,
        )}/${encodeURIComponent(option.organizationNumber)}`,
      );
      setSearchInputValue('');
      handleDropdownClose();
      setIsSearchDrawerOpen && setIsSearchDrawerOpen(false);
      window.scrollTo(0, 0);
    },
    [handleDropdownClose, push, setIsSearchDrawerOpen],
  );

  return (
    options && (
      <div
        style={{ paddingRight: 0 }}
        className={
          isHeaderSearchBar
            ? classes.searchBarHeaderWrapper
            : classes.searchBarWrapper
        }
      >
        <Autocomplete
          forcePopupIcon={false}
          inputValue={searchInputValue}
          loading={isLoadingCompanySearchOptions}
          onChange={(_, option) =>
            handleOptionSelect(option as CompanySearchOption)
          }
          noOptionsText="No results"
          componentsProps={{
            popper: {
              className: `${isDrawerSearch ? 'isDrawerSearch' : ''}`,
              modifiers: [
                {
                  name: 'flip',
                  enabled: false,
                },
              ],
            },
          }}
          getOptionLabel={(option) =>
            typeof option === 'string'
              ? option
              : `${option.name} ${option.organizationNumber}`
          }
          options={isMobile ? options.slice(0, 8) : options.slice(0, 5)}
          renderOption={(props, option) => (
            <Box
              key={option.organizationNumber}
              component="li"
              {...props}
              className={classes.optionsItem}
            >
              <div className={classes.optionTitle}>
                {createIndustryIndicator(option.sectionIndustryCode)}
                {option.name}
              </div>
              <div className={classes.optionSubtitle}>{`${
                option.organizationNumber
              } ${option.lei ? '\u2022 ' + option.lei : ''}`}</div>
            </Box>
          )}
          sx={{ flex: 1 }}
          open={isDropdownOpen}
          onInputChange={(event, value) => {
            if (
              event?.type === EventType.CHANGE &&
              value.length > 0 &&
              !isDropdownOpen
            ) {
              return handleDropdownOpen();
            } else if (event?.type === EventType.CLICK) {
              return handleDropdownClose();
            }
          }}
          onOpen={handleDropdownOpen}
          onClick={handleDropdownClose}
          onClose={handleDropdownClose}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ height: '100%' }}
              className={
                isHeaderSearchBar
                  ? classes.searchBarHeaderInput
                  : classes.searchBarInput
              }
              variant="outlined"
              size={isHeaderSearchBar ? 'small' : 'medium'}
              onChange={handleOnChange}
              placeholder={
                isDrawerSearch
                  ? 'Click here to search for companies'
                  : 'Search Company'
              }
              onKeyDown={handleEnterKeyDown}
              InputProps={
                isHeaderSearchBar
                  ? {
                      ...params.InputProps,
                      type: 'search',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search
                            style={{ fill: '#d9d9d9', cursor: 'pointer' }}
                            onClick={getSearchCompanies}
                          />
                        </InputAdornment>
                      ),
                    }
                  : {
                      ...params.InputProps,
                      type: 'search',
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }
              }
              fullWidth
            />
          )}
        />
        {!isHeaderSearchBar && !isMobile && !isDrawerSearch && (
          <Button
            onClick={getSearchCompanies}
            variant="contained"
            className={classes.button}
          >
            Search
          </Button>
        )}
      </div>
    )
  );
};
