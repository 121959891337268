import { BreakpointsOptions, createTheme } from '@mui/system';

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1650,
  },
};
export const { values: breakpointsValues } = breakpoints;

export const theme = createTheme({
  breakpoints,
  typography: {
    h1: {
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '32px',
      textTransform: 'none',
      letterSpacing: '0',
      color: '#1c1b1f',
    },
    h2: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '30px',
      lineHeight: '35px',
      color: '#13161c',
      letterSpacing: '1px',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '16px',
      textTransform: 'none',
      letterSpacing: '0',
      color: '#1c1b1f',
    },
    h4: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '20px',
      lineHeight: '23px',
      color: '#13161c',
      letterSpacing: '1px',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    h5: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '17px',
      lineHeight: '20px',
      color: '#0000008a',
      fontWeight: 700,
    },
    h6: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '17px',
      lineHeight: '25px',
      color: '#13161c',
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '17px',
      lineHeight: '25px',
      color: '#13161c',
    },
    body2: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      color: '#13161c',
      fontWeight: 400,
    },
    button: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '17px',
      lineHeight: '20px',
      color: '#13161c',
      fontWeight: 500,
      letterSpacing: '0.59px',
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: null,
    },
    MuiContainer: {
      styleOverrides: null,
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          borderBottom: '1px solid #eaeaea',
          background: '#fff ',
          boxSizing: 'border-box ',
          boxShadow: '2px 3px 6px 1px rgba(235, 235, 235, 0.79)',
        },
        elevation1: null,
        elevation2: {
          border: 'none !important',
          boxShadow: 'none  !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: null,
    },
  },
});
