import ReactGA from 'react-ga4';
import { EventActions } from './EventActions';

interface eventTrackType {
  action: EventActions;
  label: string;
  category?: string;
}

export const GA4eventTrack = ({
    action,
    label,
    category= 'Custom_event'
  }: eventTrackType) =>
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
  