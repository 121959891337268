import { Toolbar } from '@nordictrustee/nt-ui-library';
import { ToolbarWithClassNameProps } from 'components/Layout/atoms/Navbar/atoms/ToolbarWithClassName/ToolbarWithClassName.types';

export const ToolbarWithClassName = ({
  children,
  className,
}: ToolbarWithClassNameProps) => {
  return (
    <div className={className}>
      <Toolbar>{children}</Toolbar>
    </div>
  );
};
