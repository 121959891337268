import { useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { Button, EnvironmentChip } from '@nordictrustee/nt-ui-library';
import Logo from 'assets/ESGPlatformLogo.svg';
import { useDetectBreakpoint } from 'hooks/useDetectBreakpoint';
import * as URL from 'router/url';
import { LearningCenterSections } from 'screens/LearningCenterPage/TabMenu/LearningCenterSections';
import { ToolbarWithClassName } from 'components/Layout/atoms/Navbar/atoms/ToolbarWithClassName/ToolbarWithClassName';
import { NavbarProps } from 'components/Layout/atoms/Navbar/Navbar.types';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { extractUrlFromPath } from 'utils/extractUrlFromPath';
import { EnvironmentName } from 'utils/types/EnvironmentName';
import { NavigationDrawer } from './atoms/NavigationDrawer/NavigationDrawer';
import { SearchDrawer } from './atoms/SearchDrawer/SearchDrawer';
import classes from './Navbar.module.css';

const { ENVIRONMENT_NAME } = window._env_;

const mainMenuPositions = [
  { label: 'latest reports', link: URL.LATEST_REPORTS },
  { label: 'league tables', link: URL.LEAGUE_TABLE },
  { label: 'industry averages', link: URL.INDUSTRY_AVERAGES },
  { label: 'products', link: URL.PRODUCTS },
  {
    label: 'learning center',
    link: `${URL.LEARNING_CENTER}/${LearningCenterSections.FAQ}/`,
  },
  { label: 'insights', link: URL.INSIGHTS },
];

export const Navbar = ({ children }: NavbarProps) => {
  const location = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);

  const isSearchBarVisible = useMemo(
    () =>
      location.pathname === URL.LATEST_REPORTS ||
      location.pathname === URL.INDUSTRY_AVERAGES ||
      extractUrlFromPath(location.pathname) === URL.COMPANY ||
      location.pathname === URL.DISCLAIMER_PAGE ||
      location.pathname === URL.ADD_ESG_REPORT ||
      location.pathname === URL.GREETINGS_FROM_STAMDATA ||
      location.pathname === URL.PRODUCTS ||
      location.pathname === URL.USER_TERMS ||
      extractUrlFromPath(location.pathname) === URL.LEARNING_CENTER ||
      extractUrlFromPath(location.pathname) === URL.LEAGUE_TABLE,
    [location.pathname],
  );

  const isMobileOrTablet = useDetectBreakpoint('md');

  const onNavClick = (navLink: string) => {
    if (location.pathname === navLink) window.scrollTo(0, 0);
  };

  return (
    <>
      <NavigationDrawer
        setIsDrawerOpen={setIsDrawerOpen}
        isDrawerOpen={isDrawerOpen}
        onNavClick={onNavClick}
      />
      <SearchDrawer
        setIsSearchDrawerOpen={setIsSearchDrawerOpen}
        isSearchDrawerOpen={isSearchDrawerOpen}
      />
      <header className={`${classes.header}`}>
        <ToolbarWithClassName className={classes.headerToolbar}>
          <>
            <div className={classes.leftToolbar}>
              <div className={classes.mainMenu__box}>
                <NavLink to={URL.ROOT}>
                  <img src={Logo} className={classes.logo} alt="Logo" />
                </NavLink>
              </div>
              {ENVIRONMENT_NAME &&
                ENVIRONMENT_NAME !== EnvironmentName.prod && (
                  <EnvironmentChip environmentName={ENVIRONMENT_NAME} />
                )}
              {!isMobileOrTablet && isSearchBarVisible && (
                <SearchBar isHeaderSearchBar />
              )}
            </div>
            {!isMobileOrTablet && (
              <div className={classes.rightToolbar}>
                {mainMenuPositions.map(({ link, label }) => (
                  <div className={classes.mainMenu__box} key={link}>
                    <NavLink
                      activeStyle={{
                        backgroundColor: ' rgba(255, 255, 255, 0.20)',
                      }}
                      className={classes.mainMenu__link}
                      data-testid={link}
                      to={link}
                      onClick={() => onNavClick(link)}
                    >
                      {label}
                    </NavLink>
                  </div>
                ))}
                <NavLink
                  className={classes.link}
                  data-testid={URL.ADD_ESG_REPORT}
                  to={URL.ADD_ESG_REPORT}
                  onClick={() => onNavClick(URL.ADD_ESG_REPORT)}
                >
                  <Button className={classes.esgButton}>ADD ESG REPORT</Button>
                </NavLink>
              </div>
            )}
            {isMobileOrTablet && (
              <>
                <IconButton
                  sx={{ color: 'white', p: '6px' }}
                  disableRipple
                  onClick={() => setIsSearchDrawerOpen(true)}
                >
                  <SearchIcon sx={{ fontSize: '32px' }} />
                </IconButton>
                <IconButton
                  sx={{ color: 'white', p: '6px' }}
                  disableRipple
                  onClick={() => setIsDrawerOpen(true)}
                >
                  <MenuIcon sx={{ fontSize: '32px' }} />
                </IconButton>
              </>
            )}
          </>
        </ToolbarWithClassName>
        {children}
      </header>
    </>
  );
};
