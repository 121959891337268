import {
  FactorCodeNordicTrustee,
  FactorCodePublicSourcesCode,
  IndicatorCode,
  IndicatorsHybridCode,
} from 'screens/CompanyLandingPage/context/CompanyLandingPageContext.types';

export const historyActions = {
  PUSH: 'PUSH',
  POP: 'POP',
  REPLACE: 'REPLACE',
};

export enum Keyboard {
  ENTER = 'Enter',
  NUMPAD_ENTER = 'NumpadEnter',
}

export enum EventType {
  CLICK = 'click',
  CHANGE = 'change',
}

export enum RankLabel {
  BEST = 'BEST',
  GOOD = 'GOOD',
  BAD = 'BAD',
  WORST = 'WORST',
}

export const tableVisibleFactorsNordicTrustee = [
  FactorCodeNordicTrustee.SCOPE1_GHG_EMISSIONS,
  FactorCodeNordicTrustee.SCOPE2_GHG_EMISSIONS_LOCATION_BASED,
  FactorCodeNordicTrustee.SCOPE2_GHG_EMISSIONS_MARKET_BASED,
  FactorCodeNordicTrustee.SCOPE3_GHG_EMISSIONS,
  FactorCodeNordicTrustee.TOTAL_GHG_EMISSIONS,
  FactorCodeNordicTrustee.NON_RENEWABLE_ENERGY_CONSUMPTION,
  FactorCodeNordicTrustee.NON_RENEWABLE_ENERGY_PRODUCTION,
  FactorCodeNordicTrustee.RENEWABLE_ENERGY_CONSUMPTION,
  FactorCodeNordicTrustee.RENEWABLE_ENERGY_PRODUCTION,
  FactorCodeNordicTrustee.EMISSIONS_TO_WATER,
  FactorCodeNordicTrustee.HAZARDOUS_AND_RADIOACTIVE_WASTE,
  FactorCodeNordicTrustee.UNADJUSTED_GENDER_PAY_GAP,
] as const;

export const tableVisibleFactorsPublicSources = [
  FactorCodePublicSourcesCode.EMISSION_INTENSITY_FOR_GREENHOUSE_GASES,
  FactorCodePublicSourcesCode.ENERGY_CONSUMPTION_INTENSITY,
  FactorCodePublicSourcesCode.MALES_AVERAGE_MONTHLY_EARNINGS,
  FactorCodePublicSourcesCode.FEMALES_AVERAGE_MONTHLY_EARNINGS,
  FactorCodePublicSourcesCode.MANAGER_SALARY,
  FactorCodePublicSourcesCode.WAGES_SALARIES_AND_SOCIAL_SECURITY_EXPENSES,
  FactorCodePublicSourcesCode.NUMBER_OF_EMPLOYEES,
] as const;

export const tableVisibleIndicatorsNordicTrustee = [
  IndicatorCode.SCOPE_1_GHG_EMISSIONS,
  IndicatorCode.SCOPE_2_GHG_EMISSIONS_LOCATION_BASED,
  IndicatorCode.SCOPE_2_GHG_EMISSIONS_MARKET_BASED,
  IndicatorCode.SCOPE_3_GHG_EMISSIONS,
  IndicatorCode.TOTAL_GHG_EMISSIONS,
  IndicatorCode.GHG_INTENSITY_OF_INVESTEE_COMPANIES,
  IndicatorCode.SHARE_OF_NON_RENEWABLE_ENERGY_CONSUMPTION,
  IndicatorCode.SHARE_OF_NON_RENEWABLE_ENERGY_PRODUCTION,
  IndicatorCode.SHARE_OF_NON_RENEWABLE_ENERGY_CONSUMPTION_AND_PRODUCTION,
  IndicatorCode.ENERGY_CONSUMPTION_INTENSITY_PER_HIGH_IMPACT_CLIMATE_SECTOR,
  IndicatorCode.EMISSIONS_TO_WATER,
  IndicatorCode.HAZARDOUS_WASTE,
  IndicatorCode.UNADJUSTED_GENDER_PAY_GAP,
  IndicatorCode.BOARD_GENDER_DIVERSITY,
] as const;

export const tableVisibleIndicatorsHybrid = [
  IndicatorsHybridCode.SCOPE_1_GHG_EMISSIONS,
  IndicatorsHybridCode.GHG_INTENSITY_OF_INVESTEE_COMPANIES,
  IndicatorsHybridCode.ENERGY_CONSUMPTION_INTENSITY_PER_HIGH_IMPACT_CLIMATE_SECTOR,
  IndicatorsHybridCode.HAZARDOUS_WASTE,
  IndicatorsHybridCode.EXCESSIVEE_CEO_PAY_RATIO,
  IndicatorsHybridCode.UNADJUSTED_GENDER_PAY_GAP,
] as const;

export const FACTORS_QUESTIONS: { [key: string]: string } = {
  ESG00006N: 'Have carbon reduction target(s)?',
  ESG00013N:
    'Show assessment of NEGATIVE effects on biodiversity-sensitive areas?',
  ESG00014N:
    'Have activities that negatively affects biodiversity-sensitive areas?',
  ESG00017N:
    'Violate UN Global Compact principles or Organisation for Economic Cooperation and Development (OECD) Guidelines for Multinational Enterprises?',
  ESG00018N:
    'Lack a processes and compliance mechanisms to monitor compliance with UN Global Compact principles and OECD Guidelines for Multinational Enterprises',
  ESG00024N: 'Have exposure to the fossil fuel sector?',
  ESG00028N:
    'Have exposure to controversial weapons? (antipersonnel mines, cluster munitions, chemical weapons and biological weapons)?',
  ESG00040N:
    'Have exposure to any controversial products (Tobacco, Alcohol, Adult entertainment, Gambling, Cannabis, Coal or Weapons)',
  ESG00041N: 'Have exposure to debt collection or consumer loans?',
};

export const mobileFactorsColumnNames: Record<
  (typeof tableVisibleFactorsNordicTrustee)[number],
  string
> = {
  [FactorCodeNordicTrustee.SCOPE1_GHG_EMISSIONS]: 'Scope 1',
  [FactorCodeNordicTrustee.SCOPE2_GHG_EMISSIONS_LOCATION_BASED]: 'Scope 2 L',
  [FactorCodeNordicTrustee.SCOPE2_GHG_EMISSIONS_MARKET_BASED]: 'Scope 2 M',
  [FactorCodeNordicTrustee.SCOPE3_GHG_EMISSIONS]: 'Scope 3',
  [FactorCodeNordicTrustee.TOTAL_GHG_EMISSIONS]: 'Total emissions',
  [FactorCodeNordicTrustee.NON_RENEWABLE_ENERGY_CONSUMPTION]:
    'Non-renewable energy consumption',
  [FactorCodeNordicTrustee.NON_RENEWABLE_ENERGY_PRODUCTION]:
    'Non-renewable energy production',
  [FactorCodeNordicTrustee.RENEWABLE_ENERGY_CONSUMPTION]:
    'Renewable energy consumption',
  [FactorCodeNordicTrustee.RENEWABLE_ENERGY_PRODUCTION]:
    'Renewable energy production',
  [FactorCodeNordicTrustee.EMISSIONS_TO_WATER]: 'Emissions to water',
  [FactorCodeNordicTrustee.HAZARDOUS_AND_RADIOACTIVE_WASTE]:
    'Hazardous or radioactive waste',
  [FactorCodeNordicTrustee.UNADJUSTED_GENDER_PAY_GAP]: 'Gender pay gap',
} as const;

export const mobileIndicatorsColumnNames: Record<
  (typeof tableVisibleIndicatorsNordicTrustee)[number],
  string
> = {
  [IndicatorCode.SCOPE_1_GHG_EMISSIONS]: 'Scope 1',
  [IndicatorCode.SCOPE_2_GHG_EMISSIONS_LOCATION_BASED]: 'Scope 2 L',
  [IndicatorCode.SCOPE_2_GHG_EMISSIONS_MARKET_BASED]: 'Scope 2 M',
  [IndicatorCode.SCOPE_3_GHG_EMISSIONS]: 'Scope 3',
  [IndicatorCode.TOTAL_GHG_EMISSIONS]: 'Total GHG emissions (Carbon Footprint)',
  [IndicatorCode.GHG_INTENSITY_OF_INVESTEE_COMPANIES]: 'GHG intensity',
  [IndicatorCode.SHARE_OF_NON_RENEWABLE_ENERGY_CONSUMPTION]:
    'Share of non-renewable energy consumption',
  [IndicatorCode.SHARE_OF_NON_RENEWABLE_ENERGY_PRODUCTION]:
    'Share of non-renewable energy production',
  [IndicatorCode.SHARE_OF_NON_RENEWABLE_ENERGY_CONSUMPTION_AND_PRODUCTION]:
    'Share of non-renewable energy consumption and production',
  [IndicatorCode.ENERGY_CONSUMPTION_INTENSITY_PER_HIGH_IMPACT_CLIMATE_SECTOR]:
    'Energy consumption intensity per high impact climate sector',
  [IndicatorCode.EMISSIONS_TO_WATER]: 'Emissions to water',
  [IndicatorCode.HAZARDOUS_WASTE]: 'Hazardous waste',
  [IndicatorCode.UNADJUSTED_GENDER_PAY_GAP]: 'Unadjusted gender pay gap',
  [IndicatorCode.BOARD_GENDER_DIVERSITY]: 'Board gender diversity',
} as const;

export const EMPTY_VALUE_LABEL = '—';
