import { CircularProgress } from '@mui/material';
import classes from './LoadingScreen.module.css';

export const LoadingScreen = () => {
  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
};
