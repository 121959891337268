import { Dispatch, ReactNode, SetStateAction } from 'react';
import { AxiosResponse } from 'axios';
import { CompanySearchOption } from 'context/GlobalContext/GlobalContext.types';
import { CarbonEmissionsData } from '../screens/CarbonMetrics/atoms/CarbonEmissionsSection/CarbonEmissionsSection.types';
import { EvicProductivityData } from '../screens/CarbonMetrics/atoms/EvicProductivitySection/EvicProductivitySection.types';
import { FinancialsData } from '../screens/CarbonMetrics/atoms/FinancialsSection/FinancialsSection.types';
import { RevenuesProductivityData } from '../screens/CarbonMetrics/atoms/RevenuesProductivitySection/RevenuesProductivitySection.types';

export interface CompanyInterface {
  id: number;
  name: string;
  lei: string;
  countryName: string;
  countryCode: string;
  organizationNumber: string;
  publicID: string;
  industryCodeDescription: string;
  sectionIndustryCode: string;
  industryCode: string;
  evic: number;
  evicUnit: string;
  revenues: number;
  revenuesUnit: string;
}

export interface CompanyReportsData {
  company: CompanyInterface;
  companyReports: CompanyReport[];
}

export interface CompanyReport {
  id: number;
  from: string;
  to: string;
  latestUpdate: string;
  factorsNordicTrustee: Factor[];
  factorsPublicSources: Factor[];
  paiIndicatorsNordicTrustee: Indicator[];
  paiIndicatorsHybrid: Indicator[];
  taxonomyIndicatorsNordicTrustee: Indicator[];
  taxonomyIndicatorsHybrid: Indicator[];
}

export type CompanyLandingPageContextProps = {
  children: ReactNode;
};

export type CompanyLandingPageContextValue = {
  companyReportsData?: CompanyReportsData;
  isLoadingCompanyReportsData: boolean;
  getCompanyReportsData: (publicID: string) => void;
  reportYear: string | null;
  setReportYear: Dispatch<SetStateAction<string | null>>;
  companyLogo?: { logo: string };
  isLoadingCompanyLogo: boolean;
  getCompanyLogo: (arg: string) => void;
  latestExchangeRates?: ExchangeRate[];
  isLoadingLatestExchangeRates: boolean;
  getLatestExchangeRates: (year: number) => void;
  selectedPeers: CompanySearchOption[];
  setSelectedPeers: Dispatch<SetStateAction<CompanySearchOption[]>>;
  aggregatedCompanyReportData: CompanyReportsData[];
  setAggregatedCompanyReportData: Dispatch<
    SetStateAction<CompanyReportsData[]>
  >;
  companyStructure?: CompanyStructure;
  isLoadingCompanyStructure: boolean;
  getCompanyStructure: (publicID: string) => void;
  gleifData?: GleifData;
  isLoadingGleifData: boolean;
  getGleifData: (publicID: string) => void;
  gleifResponse?: AxiosResponse<GleifData, any>;
  carbonEmissionsData: CarbonEmissionsData[];
  financialsData: FinancialsData[];
  evicProductivityData: EvicProductivityData[];
  revenuesProductivityData: RevenuesProductivityData[];
  isTaxonomyDataPresent?: boolean;
  isCarbonMetricsDataPresent?: boolean;
  isLoadingLatestFactorRanks: boolean;
  latestFactorRanks?: LastestFactorRanks;
  getCompanyLatestFactorRanks: (
    publicID: string,
    definitionCode: string,
  ) => void;
  isLoadingLatestIndicatorRanks: boolean;
  latestIndicatorRanks?: LastestIndicatorRanks;
  getCompanyLatestIndicatorRanks: (
    publicID: string,
    definitionCode: string,
  ) => void;
  peerOptions: CompanySearchOption[];
};

export type Indicator = {
  name: string;
  value: number;
  unit: string;
  code: string;
  description: string;
  formulaDescription: string;
  industryAverage: number;
  isIncreasingTrendExpected?: boolean;
  calculationDataOrigin: string;
};

export type TaxonomyData = {
  id: number;
  name: string;
  value?: number;
  unit?: string;
  percentage?: number;
};

export type Factor = {
  name: string;
  description: string;
  code: string;
  domesticValue: number;
  domesticUnit: string;
  commonValue: number;
  commonUnit: string;
  isIncreasingTrendExpected?: boolean;
  dataOrigin: string;
  dataSource: string;
};

export enum FactorCodeNordicTrustee {
  SCOPE1_GHG_EMISSIONS = 'ESG00001N',
  SCOPE2_GHG_EMISSIONS_LOCATION_BASED = 'ESG00002N',
  SCOPE2_GHG_EMISSIONS_MARKET_BASED = 'ESG00003N',
  SCOPE3_GHG_EMISSIONS = 'ESG00004N',
  TOTAL_GHG_EMISSIONS = 'ESG00005N',
  NON_RENEWABLE_ENERGY_CONSUMPTION = 'ESG00009N',
  NON_RENEWABLE_ENERGY_PRODUCTION = 'ESG00010N',
  RENEWABLE_ENERGY_CONSUMPTION = 'ESG00011N',
  RENEWABLE_ENERGY_PRODUCTION = 'ESG00012N',
  EMISSIONS_TO_WATER = 'ESG00015N',
  HAZARDOUS_AND_RADIOACTIVE_WASTE = 'ESG00016N',
  UNADJUSTED_GENDER_PAY_GAP = 'ESG00019N',
  MALE_BOARD_MEMBERS = 'ESG00021N',
  FEMALE_BOARD_MEMBERS = 'ESG00020N',
  TOTAL_TAXONOMY_REVENUES = 'ESG00025N',
  TAXONOMY_ALIGNED_REVENUES = 'ESG00026N',
  TAXONOMY_ELIGIBLE_REVENUES = 'ESG00027N',
  TOTAL_TAXONOMY_CAPEX = 'ESG00034N',
  TAXONOMY_ALIGNED_CAPEX = 'ESG00035N',
  TAXONOMY_ELIGIBLE_CAPEX = 'ESG00036N',
  TOTAL_TAXONOMY_OPEX = 'ESG00037N',
  TAXONOMY_ALIGNED_OPEX = 'ESG00038N',
  TAXONOMY_ELIGIBLE_OPEX = 'ESG00039N',
  IS_LISTED_COMPANY = 'ESG00032N',
  IS_CONSOLIDATED_CORPORATED_ACCOUNTS = 'ESG00033N',
  EVIC = 'ESG00007N',
  REVENUES = 'ESG00008N',
}

export enum FactorCodePublicSourcesCode {
  EMISSION_INTENSITY_FOR_GREENHOUSE_GASES = 'ESG00001P',
  ENERGY_CONSUMPTION_INTENSITY = 'ESG00002P',
  MALES_AVERAGE_MONTHLY_EARNINGS = 'ESG00004P',
  FEMALES_AVERAGE_MONTHLY_EARNINGS = 'ESG00005P',
  MANAGER_SALARY = 'ESG00006P',
  WAGES_SALARIES_AND_SOCIAL_SECURITY_EXPENSES = 'ESG00007P',
  NUMBER_OF_EMPLOYEES = 'ESG00008P',
}

export enum TaxonomyCode {
  ALIGNED_REVENUES = 'TAX00001N',
  ELIGIBLE_REVENUES = 'TAX00002N',
  ALIGNED_CAPEX = 'TAX00003N',
  ELIGIBLE_CAPEX = 'TAX00004N',
  ALIGNED_OPEX = 'TAX00005N',
  ELIGIBLE_OPEX = 'TAX00006N',
}

export enum IndicatorCode {
  SCOPE_1_GHG_EMISSIONS = 'PAI01001.1N',
  SCOPE_2_GHG_EMISSIONS_LOCATION_BASED = 'PAI01001.2N',
  SCOPE_2_GHG_EMISSIONS_MARKET_BASED = 'PAI01001.3N',
  SCOPE_3_GHG_EMISSIONS = 'PAI01001.4N',
  TOTAL_GHG_EMISSIONS = 'PAI01001.5N',
  GHG_INTENSITY_OF_INVESTEE_COMPANIES = 'PAI01002.1N',
  COMPANIES_ACTIVE_IN_THE_FOSSIL_FUEL_SECTOR = 'PAI01003.1N',
  SHARE_OF_NON_RENEWABLE_ENERGY_CONSUMPTION = 'PAI01004.1N',
  SHARE_OF_NON_RENEWABLE_ENERGY_PRODUCTION = 'PAI01005.1N',
  SHARE_OF_NON_RENEWABLE_ENERGY_CONSUMPTION_AND_PRODUCTION = 'PAI01006.1N',
  ENERGY_CONSUMPTION_INTENSITY_PER_HIGH_IMPACT_CLIMATE_SECTOR = 'PAI01007.1N',
  ASSESSMENT_OF_NEGATIVE_EFFECTS_ON_BIODIVERSITY_SENSITIVE_AREAS = 'PAI01008.1N',
  ACTIVITIES_NEGATIVELY_AFFECTING_BIODIVERSITY_SENSITIVE_AREAS = 'PAI01009.1N',
  EMISSIONS_TO_WATER = 'PAI01010.1N',
  HAZARDOUS_WASTE = 'PAI01011.1N',
  VIOLATIONS_OF_UN_GLOBAL_COMPACT_PRINCIPLES_AND_OECD_GUIDELINES = 'PAI01012.1N',
  LACK_OF_PROCESSES_AND_COMPLIANCE_MECHANISMS = 'PAI01013.1N',
  UNADJUSTED_GENDER_PAY_GAP = 'PAI01014.1N',
  BOARD_GENDER_DIVERSITY = 'PAI01015.1N',
  CARBON_REDUCTION_TARGETS = 'PAI01016.1N',
  EXPOSURE_TO_CONTROVERSIAL_WEAPONS = 'PAI01017.1N',
}

export enum IndicatorsHybridCode {
  SCOPE_1_GHG_EMISSIONS = 'PAI01001.1H',
  GHG_INTENSITY_OF_INVESTEE_COMPANIES = 'PAI01002.1H',
  ENERGY_CONSUMPTION_INTENSITY_PER_HIGH_IMPACT_CLIMATE_SECTOR = 'PAI01007.1H',
  HAZARDOUS_WASTE = 'PAI01011.1H',
  EXCESSIVEE_CEO_PAY_RATIO = 'PAI01018.1H',
  UNADJUSTED_GENDER_PAY_GAP = 'PAI01014.1H',
}

export interface ExchangeRate {
  currency: string;
  rate: number;
  date: string;
}

interface EntityAddress {
  firstAddressLine: string;
  city: string;
  region: string;
  country: string;
  postalCode: string;
  additionalAddressLine: string[];
}

interface RegistrationAuthorityJurisdiction {
  country: string;
  jurisdiction: string;
}

interface RegistrationAuthority {
  registrationAuthorityID: string;
  registrationAuthorityInternationalName: string;
  registrationAuthorityLocalName: string | null;
  registrationAuthorityInternationalOrganizationName: string;
  registrationAuthorityLocalOrganizationName: string | null;
  registrationAuthorityJurisdictions: RegistrationAuthorityJurisdiction[];
  registrationAuthorityEntityID: string;
  otherRegistrationAuthorityID: string | null;
}

interface EntityFormName {
  localName: string;
  languageCode: string;
}

interface LegalForm {
  entityLegalFormCode: string;
  otherLegalForm: string | null;
  names: EntityFormName[];
}

interface AssociatedEntity {
  associatedLEI: string | null;
  associatedEntityName: string | null;
}

interface SuccessorEntity {
  successorLEI: string | null;
  successorEntityName: string | null;
}

interface Entity {
  legalName: string;
  otherEntityNames: string[];
  legalAddress: EntityAddress;
  headquartersAddress: EntityAddress;
  otherAddresses: EntityAddress[];
  registrationAuthority: RegistrationAuthority;
  legalJurisdiction: string;
  legalForm: LegalForm;
  associatedEntity: AssociatedEntity;
  entityStatus: string;
  entityCategory: string;
  entityExpirationDate: string | null;
  entityExpirationReason: string | null;
  successorEntity: SuccessorEntity;
}

interface RegistrationNew {
  initialRegistrationDate: string;
  lastUpdateDate: string;
  registrationStatus: string;
  nextRenewalDate: string;
  managingLOU: string;
  leiIssuerName: string;
  leiIssuerMarketingName: string;
  validationSources: string;
  validationAuthority: {
    validationAuthorityID: string;
    validationAuthorityEntityID: string;
    otherValidationAuthorityID: string | null;
  };
  otherValidationAuthorities: string[];
}
export interface GleifData {
  latestUpdate: string;
  lei: string;
  entityNew: Entity;
  registrationNew: RegistrationNew;
  bic: string[];
}

interface DirectChildren {
  lei: string;
  name: string;
}

interface DirectParent {
  lei: string;
  name: string;
}

export interface CompanyStructure {
  directChildren: DirectChildren[] | null;
  directParent: DirectParent | null;
  ultimateParent: DirectParent | null;
  lei: string;
  name: string;
}

export interface RankInfo {
  rank: number;
  reverseRank: number;
  percentRank: number;
  reversePercentRank: number;
  totalRanked: number;
  isIncresingTrendExpected: boolean;
}

export interface LastestFactorRanks {
  factorLeagueOverallRank: RankInfo;
  factorLeagueCountryRank: RankInfo;
  factorLeagueIndustryRank: RankInfo;
}

export interface LastestIndicatorRanks {
  paiIndicatorLeagueOverallRank: RankInfo;
  paiIndicatorLeagueCountryRank: RankInfo;
  paiIndicatorLeagueIndustryRank: RankInfo;
}
