import { PropsWithChildren } from 'react';
import { Container } from '@mui/system';

interface Props extends PropsWithChildren {
  className?: string;
}

export const SectionContainer = ({ children, className }: Props) => {
  return (
    <Container disableGutters maxWidth={false} className={className}>
      <Container maxWidth="xl" sx={{ px: '12px' }}>
        {children}
      </Container>
    </Container>
  );
};
