import {
  CompanyReportPeriod,
  CompanySearchOption,
  IndustryAveragesType,
  LatestReports,
  LeagueTableOptions,
  Statistics,
} from 'context/GlobalContext/GlobalContext.types';
import { mapOptionsToValueLabelObject } from 'context/GlobalContext/utils';
import { useAxios } from 'hooks/useAxios';
import { SelectOptionWithDescription } from 'screens/IndustryAveragesPage/utils';

export const useGetCompanySearchOptions = () => {
  const [
    { data: companySearchOptions, loading: isLoadingCompanySearchOptions },
    getCompanySearchOptions,
  ] = useAxios<CompanySearchOption[]>(
    {
      url: '/companies/search-options',
    },
    {
      manual: true,
    },
  );

  return {
    companySearchOptions,
    isLoadingCompanySearchOptions,
    getCompanySearchOptions,
  };
};

export const useGetAllowedUnits = () => {
  const [{ data: unitOptions, loading: isLoadingUnits }, getAllowedUnits] =
    useAxios<SelectOptionWithDescription[]>(`/dictionaries/units`, {
      manual: true,
    });

  return { unitOptions, isLoadingUnits, getAllowedUnits };
};

export const useGetPeriods = () => {
  const [{ data: yearsOptions, loading: isLoadingYearOptions }, getPeriods] =
    useAxios<CompanyReportPeriod[]>(`/dictionaries/periods`, {
      manual: false,
    });

  const yearsLookup = mapOptionsToValueLabelObject(yearsOptions);

  return {
    yearsLookup,
    yearsOptions,
    isLoadingYearOptions,
    getPeriods,
  };
};

export const useGetIndustryAverages = () => {
  const [
    { data: industryAverages, loading: isIndustryAveragesLoading },
    getIndustryAverages,
  ] = useAxios<IndustryAveragesType[]>(
    {
      url: `/industry-codes`,
    },
    {
      manual: true,
    },
  );
  return { industryAverages, isIndustryAveragesLoading, getIndustryAverages };
};

export const useGetLeagueTableOptions = () => {
  const [
    { data: leagueTableOptions, loading: isLoadingLeagueTableOptions },
    getLeagueTableOptions,
  ] = useAxios<LeagueTableOptions>(
    {
      url: '/dictionaries/league-tables-options',
    },
    {
      manual: true,
    },
  );

  return {
    leagueTableOptions,
    isLoadingLeagueTableOptions,
    getLeagueTableOptions,
  };
};

export const useGetLatestReports = () => {
  const [
    { data: latestReports, loading: isLoadingLatestReports },
    getLatestReports,
  ] = useAxios<LatestReports[]>(
    {
      url: '/landing-page/latest-reports',
    },
    {
      manual: true,
    },
  );
  return {
    latestReports,
    isLoadingLatestReports,
    getLatestReports,
  };
};

export const useGetStatistics = () => {
  const [{ data: statistics }, getStatistics] = useAxios<Statistics>(
    {
      url: '/landing-page/statistics',
    },
    {
      manual: true,
    },
  );
  return {
    statistics,
    getStatistics,
  };
};
