import { Dispatch } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Drawer, IconButton } from '@mui/material';
import { SearchBar } from 'components/SearchBar/SearchBar';
import classes from './SearchDrawer.module.css';

type Props = {
  setIsSearchDrawerOpen: Dispatch<React.SetStateAction<boolean>>;
  isSearchDrawerOpen: boolean;
};

export const SearchDrawer = ({
  isSearchDrawerOpen,
  setIsSearchDrawerOpen,
}: Props) => {
  return (
    <Drawer
      anchor="right"
      open={isSearchDrawerOpen}
      onClose={() => setIsSearchDrawerOpen(false)}
      className={classes.drawer}
    >
      <Box
        role="navigation"
        sx={{ background: 'white', height: '100vh', width: '100vw' }}
      >
        <div className={classes.drawerHeader}>
          <IconButton>
            <ArrowBackIcon
              onClick={() => setIsSearchDrawerOpen(false)}
              sx={{ color: 'white' }}
              fontSize="medium"
            />
          </IconButton>
          <SearchBar
            isDrawerSearch
            setIsSearchDrawerOpen={setIsSearchDrawerOpen}
          />
        </div>
      </Box>
    </Drawer>
  );
};
