import { CompanyReportPeriod } from 'context/GlobalContext/GlobalContext.types';

export const mapOptionsToValueLabelObject = (arr?: CompanyReportPeriod[]) =>
  arr
    ? arr.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.label]: cur.label,
        }),
        {},
      )
    : [];
