import { lazy, Suspense } from 'react';
import PhoneRotateIcon from 'assets/PhoneRotateIcon.svg';
import { Footer } from 'components/Layout/atoms/Footer/Footer';
import { LayoutContainer } from 'components/Layout/atoms/LayoutContainer/LayoutContainer';
import { Navbar } from 'components/Layout/atoms/Navbar/Navbar';
import { LayoutProps } from 'components/Layout/Layout.types';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';

const GlobalContextProvider = lazy(
  () => import('context/GlobalContext/GlobalContext'),
);

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <div className="landscape-warning">
        <img src={PhoneRotateIcon} alt="Phone rotate icon" />
        <div className="landscape-warning-content">
          Please rotate your device to portrait orientation for the best
          experience. This application is designed for portrait mode viewing.
        </div>
      </div>
      <Suspense fallback={<LoadingScreen />}>
        <GlobalContextProvider>
          <Navbar />
          <LayoutContainer>{children}</LayoutContainer>
          <Footer />
        </GlobalContextProvider>
      </Suspense>
    </>
  );
};
